import React from 'react'
import { graphql, navigate } from 'gatsby'
import Seo from '../components/seo/seo'
import Layout from '../components/layout/Layout'
import insightsImg from '../img/heroImg/insights.png'
import moment from 'moment'
const config = require('../../gatsby-config')

const BlogList = ({ data }) => {
  const apiUrl = config.siteMetadata.apiUrl
  const blogList = (data?.allBlogPost?.nodes || []).filter(
    (d) => d.jobTypeId == null
  )

  return (
    <>
      <Seo
        title={'Blog | immence'}
        description={"We're always learning. Sometimes we write about it."}
      />
      <Layout>
        {/* hero section */}
        <section
          className="content-page bg-service blog_banner blogContent"
          id="service_page"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content_padd">
                  <div className="row">
                    <h2 className="hero-heading">
                      Explore our
                      <br />
                      <span className="blue-span"> Insights. </span>
                    </h2>
                  </div>
                  <div className="row">
                    <p className="section-main-para heading-setting mb-0">
                      We're always learning. Sometimes we write about it.
                    </p>
                    <p className="section-main-para heading-setting mb-0">
                      Catch a glimpse of our recent articles — we dig deep on
                      the most promising tech trends and offer plenty of
                      actionable best practices and hands-on analytics.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 center-item">
                <div className="service_img">
                  <img src={insightsImg} alt="insights" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end hero section */}
        <div className="sectionPadding">
          <div className="blogSection">
            <div className="container">
              <div className="row">
                {blogList?.map((rowData) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                      onClick={() => navigate(`/blog/${rowData?.slug}`)}
                    >
                      <div className="blogInfo">
                        <a href={`/blog/${rowData?.slug}`}>
                          <img
                            src={`${apiUrl}${rowData?.coverImage}`}
                            alt={`cover-img_${rowData?.slug}`}
                            className="img-fluid"
                          />
                        </a>
                        <div className="blogPaddBox">
                          <div className="advertising">
                            {/* {rowData?.blogTagName?.map((tag) => (
                              <a
                                href
                                style={{ marginRight: '5px' }}
                              >{`${tag}`}</a>
                            ))} */}
                            <a href style={{ marginRight: '5px' }}>
                              {rowData?.blogCategoryName}
                            </a>
                          </div>
                          <h1 className="blogTitle">
                            <a
                              href={`/blog/${rowData?.slug}`}
                              dangerouslySetInnerHTML={{
                                __html: rowData?.title,
                              }}
                            />
                          </h1>
                          <p className="desc">{rowData?.description}</p>
                          <div className="blogBox">
                            <div className="authorImg">
                              <img
                                src={`${apiUrl}${rowData?.uploadedByEmployeeAvatar}`}
                                alt="name"
                                className="img-fluid userImg"
                              />
                            </div>
                            <div className="authorInfo">
                              <div>
                                <p className="authorName">
                                  {rowData?.uploadedByEmployeeName}
                                </p>
                              </div>
                              <div className="dateTime">
                                <p className="date">
                                  {rowData?.publishDate
                                    ? moment(rowData?.publishDate).format(
                                        'MMM DD, YYYY'
                                      )
                                    : ''}
                                </p>
                                <div className="postTime">
                                  <span className="bull">•</span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z" />
                                  </svg>
                                  <p>
                                    {rowData?.readingTime
                                      ? `${rowData?.readingTime} min`
                                      : ``}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allBlogPost(sort: { fields: publishDate, order: DESC }) {
      nodes {
        id
        title
        slug
        content
        description
        coverImage
        blogCategoryId
        blogCategoryName
        blogTagId
        blogTagName
        readingTime
        metaTitle
        metaDescription
        metaKeywords
        publish
        publishDate
        uploadedByEmployeeId
        uploadedByEmployeeName
        uploadedByEmployeeAvatar
        isActive
        isDeleted
        createdAt
        updatedAt
        jobTypeId
      }
    }
  }
`

export default BlogList
